/**
 * Count input with increment (+) and decrement (-) buttons
 */

export default (() => {
  const countInputs = document.querySelectorAll('.count-input')

  for (let i = 0; i < countInputs.length; i++) {
    const component = countInputs[i]
    const incrementBtn = component.querySelector('[data-increment]')
    const decrementBtn = component.querySelector('[data-decrement]')
    const input = component.querySelector('.form-control')

    const handleIncrement = () => {
      let max = parseInt(input.getAttribute('max'))
      if (max < 1 || !max) {
        max = 1
      }
      if (parseInt(input.value) < max) {
        input.value++
        input.dispatchEvent(new Event('change'))
      }
    }

    const handleDecrement = () => {
      let min = parseInt(input.getAttribute('min'))
      if (min < 1 || !min) {
        min = 1
      }
      if (parseInt(input.value) > min) {
        input.value--
        input.dispatchEvent(new Event('change'))
      }
    }

    // Add click event to buttons
    incrementBtn.addEventListener('click', handleIncrement)
    decrementBtn.addEventListener('click', handleDecrement)
  }
})()
